<template>
    <div class="profile-card">
        <img src="@/assets/juju_bjj.png" alt="Juju BJJ" />
        <h1>Juniper Rose</h1>
        <p>California, USA</p>
        <p>Interests:</p>
    </div>
</template>

<script setup>

</script>

<style scoped>
.profile-card {
    margin-top: 18rem !important;
    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
    border: 0.0625rem solid rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding-bottom: 2rem;

    font-family: 'Roboto', sans-serif;
    font-weight:100;
}
.profile-card img {
    max-height: 16rem;
    margin-top: -16rem;
    position: relative;
    top: -1.1rem;
}
</style>