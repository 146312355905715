<template>
    <div class="flex-container">
        <h1 class="logo"><router-link to="/"><span class="logo-j">JUNIPER</span><span class="logo-r">ROSE</span></router-link></h1>
        <ul class="navigation">
            <li>about me</li>
            <li><router-link to="/ioe">my projects</router-link></li>
        </ul>
    </div>
</template>

<script setup>

</script>

<style scoped>
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 1rem auto 0;
}
.logo {
    margin: 0 0 0 .45em;
    font-family: 'Abril Fatface', cursive;
}

.logo-j {
    color: #ffffff;
}
.logo-r {
    color: #dda0ad;
}
.navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    list-style: none;
    margin: 0;

    color: whitesmoke;
    font-weight: bold;
}

.navigation li {
    padding: 0 20px;
}

a {
    text-decoration: none;
}


a:visited {
    color: white;
}

a:hover {
    color: rgb(235, 235, 235);
    filter: drop-shadow(2px,2px,5px,black);
}

@media all and (max-width: 990px) {
    .flex-container {
        flex-direction: column;
    }
    .navigation {
        width: 100%;
        justify-content: space-around;
    }
    .logo{
        margin:0;
    }
}

@media all and (max-width: 600px) {
    .logo {
        margin: .25em 0;
        align-self: left;
    }
    .navigation {
        flex-direction: column;
    }
}
</style>