<template>
    <div class="top-background">
        <div class="skewed-shape">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<style scoped>
.top-background {
    height: 580px;
    width: 100%;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    top: 0;
    z-index: -1;
}
.skewed-shape {
    background: linear-gradient(150deg,#281483 15%,#8f6ed5 70%,#d782d9 94%);
    transform: skewy(-8deg);
    transform-origin: 0;
    height: 100%;
    widows: 100%;
}

span {
    background: hsla(0,0%,100%,.1);

    height: 120px;
    width: 120px;
    border-radius: 50%;

    transform: skew(8deg);

    position: absolute;
}

span:first-child {
    left: -4%;
    bottom: auto;
}

span:nth-child(2){
    right: 4%;
    top:30%;
}

span:nth-child(3){
    right: 6%;
    top: 40%;
}

span:nth-child(4){
    right: 40%;
    top: 50%;
}

span:nth-child(5){
    left: 30%;
    bottom: 40%;
}

span:nth-child(6){
    left: 15%;
    bottom: 20%;
    height: 200px;
    width: 200px;
}
</style>